<template lang="html">
  <div>
    <template>
      <div
        id="data-list-list-view"
        class="data-list-container"
      >
        <vs-row class="pt-6">
          <vs-col
            vs-w="6"
            vs-type="flex"
          >
            <vs-button
              class="mb-1"
              color="primary"
              type="gradient"
              icon-pack="material-icons"
              icon="fast_rewind"
              @click="$router.push({name: 'Cashier'}).catch(() => {})"
            > {{ $t('Cashiers') }}
            </vs-button>
          </vs-col>
        </vs-row>
        <vs-table
          search
          max-items="5"
          pagination
          :data="trashedCashiers"
        >
          <template slot="thead">
            <vs-th sort-key="id">
              {{ $t('ID') }}
            </vs-th>
            <vs-th sort-key="name">
              {{ $t('Name') }}
            </vs-th>
            <vs-th>{{ $t('Incomming') }}</vs-th>
            <vs-th>{{ $t('Outcomming') }}</vs-th>
            <vs-th>{{ $t('Balance') }}</vs-th>
            <vs-th>{{ $t('Last Transaction') }}</vs-th>
            <vs-th>{{ $t('Dates') }}</vs-th>
            <vs-th>{{ $t('Options') }}</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr
              v-for="(tr, index) in data"
              :key="index"
            >
              <vs-td>
                <vs-chip
                  transparent
                  color="primary"
                >
                  {{ tr.id }}
                </vs-chip>
              </vs-td>
              <vs-td>
                {{ tr.name }}
              </vs-td>
              <vs-td>
                <font-awesome-icon
                  color="rgb(var(--vs-success))"
                  :icon="['fas', 'arrow-down']"
                />
                {{ tr.income }}
              </vs-td>
              <vs-td>
                <font-awesome-icon
                  color="rgb(var(--vs-danger))"
                  :icon="['fas', 'arrow-up']"
                />
                {{ tr.outcome }}
              </vs-td>
              <vs-td>
                <font-awesome-icon
                  color="rgb(var(--vs-warning))"
                  :icon="['fas', 'exchange-alt']"
                />
                {{ tr.balance }}
              </vs-td>
              <vs-td>
                <VxTooltip
                  :color="tr.last_transaction ? 'primary' : 'warning'"
                >
                  <template #title>
                    Last transaction
                  </template>
                  <template #text>
                    <span v-if="tr.last_transaction_from">

                      {{ tr.last_transaction }}
                    </span>

                    <span v-else>{{ $t("no transaction yet") }}</span>
                  </template>
                  <vx-badge
                    pack="far"
                    icon="calendar-plus"
                    :color="tr.last_transaction ? 'light-primary' : 'light-warning'"
                    :icon-color-bg="tr.last_transaction ? 'primary' : 'warning'"
                    icon-color="null"
                  >
                    <span v-if="tr.last_transaction_from"> {{ tr.last_transaction_from }}
                    </span>

                    <span v-else> {{ $t("no transaction yet") }}</span>
                  </vx-badge>
                </VxTooltip>
              </vs-td>
              <vs-td>
                <div style="width: max-content">

                  <VxTooltip
                    color="success"
                  >
                    <template #title>
                      {{ $t('Created_At') }}
                    </template>
                    <template #text>
                      {{ tr.created_at }}
                    </template>
                    <vx-badge
                      pack="far"
                      icon="calendar-plus"
                      color="light-success"
                      icon-color-bg="success"
                      icon-color="null"
                    >
                      {{ tr.created_from }}
                    </vx-badge>
                  </VxTooltip>
                </div>
                <div
                  style="width: max-content"
                  class="mt-1"
                >
                  <VxTooltip
                    color="warning"
                  >
                    <template #title>
                      {{ $t('Updated_At') }}
                    </template>
                    <template #text>
                      {{ tr.updated_at }}
                    </template>
                    <vx-badge
                      pack="fas"
                      icon="calendar-alt"
                      color="light-warning"
                      icon-color-bg="warning"
                      icon-color="null"
                    >
                      {{ tr.updated_from }}
                    </vx-badge>
                  </VxTooltip>
                </div>
                <div
                  style="width: max-content"
                  class="mt-1"
                >
                  <VxTooltip
                    color="danger"
                  >
                    <template #title>
                      {{ $t('Deleted_At') }}
                    </template>
                    <template #text>
                      {{ tr.deleted_at }}
                    </template>
                    <vx-badge
                      pack="far"
                      icon="calendar-times"
                      color="light-danger"
                      icon-color-bg="danger"
                      icon-color="null"
                    >
                      {{ tr.deleted_from }}
                    </vx-badge>
                  </VxTooltip>
                </div>
              </vs-td>
              <vs-td :data="tr.id">
                <a
                  class="ml-1 mr-1"
                  @click="RestoreCashierAlert(tr.id)"
                >
                  <font-awesome-icon
                    color="rgb(var(--vs-success))"
                    :icon="['fas', 'trash-restore']"
                  />
                </a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </template>
    <div>
      <b-card
        :title="$t('Explanation')"
        class="mt-10"
      >
        <vs-row>
          <vs-col
            class="mb-3"
            vs-w="3"
            vs-sm="6"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vx-badge
              pack="far"
              icon="calendar-times"
              color="light-danger"
              icon-color-bg="danger"
              icon-color="null"
            >

              {{ $t('Deleted At') }}
            </vx-badge>
          </vs-col>
          <vs-col
            class="mb-3"
            vs-w="3"
            vs-sm="6"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vx-badge
              pack="fas"
              icon="trash-restore"
              color="light-success"
              icon-color-bg="success"
              icon-color="null"
            >

              {{ $t('Restore Cashier') }}
            </vx-badge>
          </vs-col>
        </vs-row>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data: () => ({
    restoredCashier:'',
  }),
  computed: {
    trashedCashiers() {
      return this.$store.getters["cashier/trashedCashiers"]
    },
  },
  methods: {
    RestoreCashierAlert($val) {
      this.restoredCashier = $val
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('Confirm Restore'),
        text: this.$t('Are you sure you want to restore this Cashier'),
        accept: this.acceptAlert,
        acceptText: this.$t('Restore'),
        cancelText: this.$t('Cancel')
      })
    },
    acceptAlert() {
      this.$store.dispatch('cashier/RestoreCashier',this.restoredCashier)
    },
  },

  created() {
    this.$store.dispatch('cashier/GetTrashedData')
  }

}
</script>
<style>
.select-label-placeholder {
  position: absolute;
}

</style>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}


.vs-sidebar {
  z-index: 400000;
}
</style>
